import actions from '../actions';
import moment from 'moment-timezone';

var CalendarDayDetailSpot = function (params) {

  //console.log('params:',params);
  var startDate = moment(params.spot.start);

  var selectSpot = function (e) {
    e.preventDefault();
    e.stopPropagation();

    actions.selectSpot({ spot: params.spot });

  };

  return (<li >
    <a href="#" onClick={selectSpot}>{startDate.format('LT')}</a>

  </li>);

}
// TESTING recompose.pure for perf improvement on stateless components
//var DayDetailSpotPure = recompose.pure(DayDetailSpot);

export default  CalendarDayDetailSpot;
