import actions from '../actions';
import moment from 'moment-timezone';
import CalendarDayDetailSpot from './CalendarDayDetailSpot.jsx';

var CalendarDayDetail = function (params) {
  // params is the model
  console.log('CalendarDayDetail params :', params);
  // if ($.isEmptyObject(params.timeSlots))
  //        return false;

  // 	var day = _.find(params.timeSlots.days, {
  // 		"date": params.selectedDay
  // 	});
  // 	console.log('DayDetail params.amPmSpots :'+params.amPmSpots );
  // 	var spots = (params.amPmSpots === 'am') ? day.am_spots : day.pm_spots;
  // 	console.log('DayDetail spots :'+spots);
  //   var self=this;
  //  	var spotListHtml = [];
  //          for (var i =1 ; i<10000;i++) {
  //          	if (i===1) {var spot=spots[0]; } else 	if (i===2) {var spot=spots[1]; }  else {var spot=spots[2];}
  //  spotListHtml.push( <DayDetailSpotPure key={i+'test'} spot={spot} isSelected={params.selectedSpot && params.selectedSpot.start===spot.start} /> );
  //          }

  var spotListNodesAm = [];
  var spotListNodesPm = [];
  params.calendardaydetail.spots.forEach(function (spot, index) {

    var spotRendered = <CalendarDayDetailSpot key={index} spot={spot} />;
    if (moment(spot.start).format("A") === "AM")
      spotListNodesAm.push(spotRendered);
    else
      spotListNodesPm.push(spotRendered);

  });

  var startDateLabel = moment(params.calendardaydetail.date).format('dddd, MMM DD');

  var backToCalendar = function (e) {
    e.preventDefault();
    e.stopPropagation();

    actions.backToCalendar(params);
    return false;
  }

  return (<div id="popup-region">
    <div className="popup pal">
      <span className="nav-back-button" onClick={backToCalendar}>
        <i className="fa fa-3x fa-arrow-circle-left" aria-hidden="true"></i>
      </span>
      <div className="invitee-form one-column mvl">
        <h2>Available start times</h2>
        <div className="event-type-name">for {startDateLabel}</div>
        <p style={{
          paddingTop: '20px',
          fontWeight: '600'
        }}>AM</p>
        <ul className="nav nav-pills ">
          {spotListNodesAm}
        </ul>

        <p style={{
          paddingTop: '20px',
          fontWeight: '600'
        }}>PM</p>

        <ul className="nav nav-pills ">
          {spotListNodesPm}
        </ul>

      </div>
    </div>
  </div>);

}

export default CalendarDayDetail;
