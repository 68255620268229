import actions from '../actions';
import CalendarDay from './CalendarDay.jsx';
import moment from 'moment-timezone';

var Calendar = function (params) {
  //params is the model
  console.log('Calendar render started...');
  console.log(params);

  var calendarDisabled = '',
    navLeftDisabled = '',
    daysOfWeekNodes = [],
    calendarWeekLabel = '',
    navSpinnerStyle = {
      display: 'none'
    },
    navPeriodStyle = {
      display: 'inline-block'
    },
    timezone = moment().tz(moment.tz.guess()).format('z');

  if (params.loading) {
    calendarDisabled = 'disabled';
    navSpinnerStyle = {
      display: 'block'
    };
    navPeriodStyle = {
      display: 'none'
    };
  }

  // console.log('params.timeSlots.days:'+params.timeSlots.days.length);


  if (params && params.calendar && params.calendar.days) {
    daysOfWeekNodes = params.calendar.days.map(function (day, index) {
      // console.log(day);

      return (<CalendarDay key={index} day={day} model={params} />);
    });
    var firstDay = moment(params.calendar.days[0].date);
    var lastDay = moment(params.calendar.days[6].date);

    calendarWeekLabel = firstDay.format("MMM D");
    calendarWeekLabel += (firstDay.year() != lastDay.year())
      ? firstDay.format(", YYYY")
      : "";
    calendarWeekLabel += " - ";
    calendarWeekLabel += (firstDay.month() != lastDay.month())
      ? lastDay.format("MMM ")
      : "";
    calendarWeekLabel += lastDay.format("D");
    calendarWeekLabel += lastDay.format(", YYYY");

    navLeftDisabled = (calendarDisabled === 'disabled' || moment().format("YYYY-MM-DD") === params.calendar.weekStartDate)
      ? 'disabled'
      : '';

  }

  var gotoPrevWeek = function (e) {
    e.preventDefault();
    if (navLeftDisabled === 'disabled')
      return false;

    actions.gotoPrevWeek(params.calendar.event.id, params.calendar.startDate);

  }

  var gotoNextWeek = function (e) {
    e.preventDefault();
    if (calendarDisabled === 'disabled')
      return false;

    actions.gotoNextWeek(params.calendar.event.id, params.calendar.startDate);

  }

  var gotoToday = function (e) {
    e.preventDefault();

    actions.gotoToday(params.calendar.event.id);

  }


  return (<div className="wrapper">

    <div id="main-region">
      <div className="booking-step invitee-form pvl">
        <h1>{params.calendar.event.name}</h1>
        <p>{params.calendar.event.description}</p>

        <div className="invitee-form-column user-area pvm">

          <div className="user-area-column">
            <p>Pick Date &amp; Time</p>

          </div>
          {/* <div className="event-type-name mts">schedule an interview with {params.email} for {params.jobId}</div> */}
        </div>
        <p>
          <button type="button" className="btn btn-sm btn-default" onClick={gotoToday}>Today</button>

          <span style={{
            'paddingLeft': '20px'
          }} className="btn-group" role="navgroup" ariaLabel="navigation">
            <button type="button" className={"btn btn-sm btn-default " + navLeftDisabled} onClick={gotoPrevWeek}>
              <i className="glyphicon glyphicon-chevron-left" ariaHidden="true"></i>
            </button>
            <button type="button" className={"btn btn-sm btn-default " + calendarDisabled} onClick={gotoNextWeek}>
              <i className="glyphicon glyphicon-chevron-right" ariaHidden="true"></i>
            </button>
          </span>
          <span style={{
            'paddingLeft': '20px'
          }}>{calendarWeekLabel}</span>
        </p>
        <div className="mv-container">
          <table cellPadding="0" cellSpacing="0" className="mv-daynames-table" id="mvDaynamesTable">
            <tbody>
              <tr>
                <th className="mv-dayname" title="Sun">Sun</th>
                <th className="mv-dayname" title="Mon">Mon</th>
                <th className="mv-dayname" title="Tue">Tue</th>
                <th className="mv-dayname" title="Wed">Wed</th>
                <th className="mv-dayname" title="Thu">Thu</th>
                <th className="mv-dayname" title="Fri">Fri</th>
                <th className="mv-dayname" title="Sat">Sat</th>
              </tr>
            </tbody>
          </table>

          <div className="mv-event-container" id="mvEventContainer">

            <div className="month-row" style={{
              'top': '0%',
              'height': '21%'
            }}>

              <table cellPadding="0" cellSpacing="0" className="st-grid">
                <tbody>
                  <tr>
                    {daysOfWeekNodes}
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>);
}

export default Calendar;
