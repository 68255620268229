import actions from '../actions';

var EventList = function (params) {

  // ---- ACTIONS
  var copyEventLink = function (e, eventId) {
    e.preventDefault();


    var href = window.location.href.replace('event', 'calendar') + '/' + eventId;

    if (copyToClipboard(href)) { alert("Copied  activity link : " + href); }
    else { alert('Your browser does not support this action.'); };



  }

  var removeEvent = function (e, eventId) {
    e.preventDefault();

    actions.removeEvent(eventId);
  }

  var copyToClipboard = function (textContent) {
    // create hidden text element, if it doesn't already exist
    var targetId = "_hiddenCopyText_";

    var origSelectionStart, origSelectionEnd;

    // must use a temporary form element for the selection and copy
    target = document.getElementById(targetId);
    if (!target) {
      var target = document.createElement("textarea");
      target.style.position = "absolute";
      target.style.left = "-9999px";
      target.style.top = "0";
      target.id = targetId;
      document.body.appendChild(target);
    }
    target.textContent = textContent;

    // select the content
    var currentFocus = document.activeElement;
    target.focus();
    target.setSelectionRange(0, target.value.length);

    // copy the selection
    var succeed;
    try {
      succeed = document.execCommand("copy");
    } catch (e) {
      succeed = false;
    }
    // restore original focus
    if (currentFocus && typeof currentFocus.focus === "function") {
      currentFocus.focus();
    }


    // clear temporary content
    target.textContent = "";

    return succeed;
  };

  if (!params.events.length) {

    var eventListNodes = (<tr><td> No Activity created yet </td></tr>);

  } else {
    var eventListNodes = params.events.map(function (event, index) {


      var ui = {
        style: {
          eventButtonClassName: {
            on: (
              (!event.on)
                ? "hidden"
                : ""),
            off: (
              (event.on)
                ? "hidden"
                : "")
          }
        }
      };

      var href = "/#event/" + event.id;
      return (<tr key={index}>
        <td>
          <a href={href}>{event.name}</a>
        </td>
        <td>
          <span className="btn-group" id="toggle_event_editing">
            <span className={ui.style.eventButtonClassName.off}>OFF</span>
            <span className={ui.style.eventButtonClassName.on}>ON</span>
          </span>
        </td>
        <td>
          <span className="dropdown">
            <a href="#" className="btn dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
              More <span className="caret"></span>

            </a>
            <ul className="dropdown-menu">
              <li> <a className="dropdown-item" href="#" onClick={function (e) { copyEventLink(e, event.id); }} >Copy Link</a></li>
              <li><a className="dropdown-item" href="#" onClick={function (e) { removeEvent(e, event.id); }}>Remove</a></li>

            </ul>
          </span>


        </td>
      </tr>);
    });
  }


  return (<div className="row">
    <div className="col-lg-12">

      <a className="btn btn-primary btn-lg" href="#event/new">New Activity</a>
      <p></p>
      <table className="table">
        <thead>
          <tr>
            <th className="col-lg-10">Activity Name</th>
            <th className="col-lg-1"></th>
            <th className="col-lg-1"></th>
          </tr>
        </thead>
        <tbody>
          {eventListNodes}
        </tbody>
      </table>

    </div>
  </div>);
}

export default EventList;
