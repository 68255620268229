import model from './scripts/model';
import actions from './scripts/actions';
import state from './scripts/state';
import view from './scripts/view';
import './styles/main.css';

model.state = state;
state.view = view;
state.actions = actions;
view.actions = actions;
actions.present = model.present;

console.log('app starting ...');

actions.loadInitial(model);