
import actions from '../actions';
import moment from 'moment';
import lodash from 'lodash';

var EditEvent = function (params) {

// ---- ACTIONS
var addSlot = function (e) {
  e.preventDefault();


  var slot = parseEventSlot(newSlotInputEl.value);
  if (!slot) { return; };

  if (slot.fromDate >= slot.toDate) {
    alert('Please enter valid availability times.')
    return;
  }

  if (!params.event.slots)
    params.event.slots = [];

  var isError = false;
  params.event.slots.forEach(function (existingSlot) {
    if (existingSlot.fromDate <= slot.fromDate && existingSlot.toDate >= slot.toDate) {
      alert('Dates overlap with existing availability.');
      isError = true;
    }
  });
  if (isError) { return; };

  slot.id = uuidv4();
  console.debug('slot', slot);



  params.event.slots.push(slot);
  actions.updateTemporalEvent(params.event);

}

var removeSlot = function (e, id) {
  e.preventDefault();


  params.event.slots = params.event.slots.filter(function (item, idx) {
    return item.id !== id;
  });

  actions.updateTemporalEvent(params.event);

}

var toggleSlotRepeatWeekly = function (e, id) {

  var selectedSlot = params.event.slots.filter(function (item, idx) {
    return item.id === id;
  });
  if (!selectedSlot.length) { return; }

  selectedSlot[0].repeatWeekly = !selectedSlot[0].repeatWeekly;
  console.debug('toggleSlotRepeatWeekly', selectedSlot[0].id, selectedSlot[0].repeatWeekly);
  actions.updateTemporalEvent(params.event);




}

var saveEvent = function (e) {
  e.preventDefault();


  params.event.name = e.target['name'].value;
  params.event.description = e.target['description'].value;

  actions.saveEvent(params.event);

}

var turnEventOff = function (e) {
  e.preventDefault();


  if (!params.event.on)
    return;
  else {
    params.event.on = false;
    actions.updateTemporalEvent(params.event);
  }

};

var turnEventOn = function (e) {
  e.preventDefault();


  if (params.event.on)
    return;
  else {
    params.event.on = true;
    actions.updateTemporalEvent(params.event);
  }

};

// !---- ACTIONS

  var pageTitle = (params.event.id)
    ? "Edit Activity"
    : "New Activity";
  var dateTimeFormat = 'M/D/YYYYh:ma';
  var dateFormatForDisplay = 'M/D/YYYY';
  var timeFormatForDisplay = 'h:mm A';
  var dayOfWeekFormatForDisplay = 'dddd';

  var newSlotInputEl;

  var defaultSlot = {
    fromDate: moment().valueOf(),
    toDate: moment().add(1, 'h').valueOf(),
    duration: 15
  };

  var ui = {
    style: {
      eventButtonClassName: {
        on: "btn " + (
          (!params.event.on)
            ? "btn-default"
            : "btn-info"),
        off: "btn  " + (
          (params.event.on)
            ? "btn-default"
            : "btn-info")
      }
    }
  };


  

  var getSlotForDisplay = function (slot, isHtml) {
    var date = moment(slot.fromDate).format(dateFormatForDisplay);
    var fromTime = moment(slot.fromDate).format(timeFormatForDisplay);
    var toTime = moment(slot.toDate).format(timeFormatForDisplay);
    var dayOfWeek = moment(slot.fromDate).format(dayOfWeekFormatForDisplay);

    //(isHtml) ? '<b>' : '')
    if (slot.repeatWeekly) {
      return 'on ' + ((isHtml) ? dayOfWeek + 's ' : '') + ' from ' + fromTime + ' to ' + toTime + ' every ' + slot.duration + ' minutes starting ' + date;

    }
    else {
      return 'on ' + ((isHtml) ? dayOfWeek + ' ' : '') + date + ' from ' + fromTime + ' to ' + toTime + ' every ' + slot.duration + ' minutes';
    }

  };

  var getSlots = function () {
    if (!params.event.slots || !params.event.slots.length)
      return (<tr ><td>No availability scheduled for this event.</td></tr>);

    return lodash.orderBy(params.event.slots, 'fromDate', 'asc').map(function (item, idx) {

      //console.debug('checkedValue',item.id,checkedValue);
      return (
        <tr key={idx} >
          <td>
            {getSlotForDisplay(item, true)}
          </td>
          <td>
            <div className="checkbox-inline table-checkbox">
              <label><input type="checkbox" defaultChecked={item.repeatWeekly} onChange={function (e) {
                toggleSlotRepeatWeekly(e, item.id);
              }} /> Repeat Weekly</label>
            </div>
          </td>
          <td>
            <span className="btn btn-sm btn-default" onClick={function (e) {
              removeSlot(e, item.id);
            }}>
              Remove
        </span>
          </td>

        </tr>
      );

    });


  }

  // ---- UTIL
  var parseEventSlot = function (txt) {
    var re1 = '.*?'; // Non-greedy match on filler

    var re2 = '((?:[0]?[1-9]|[1][012])[-:\\/.](?:(?:[0-2]?\\d{1})|(?:[3][01]{1}))[-:\\/.](?:(?:[1]{1}\\d{1}\\d{1}\\d{1})|(?:[2]{1}\\d{3})))(?![\\d])'; // MMDDYYYY 1

    var re4 = '((?:(?:[0-1][0-9])|(?:[2][0-3])|(?:[0-9])):(?:[0-5][0-9])(?::[0-5][0-9])?(?:\\s?(?:am|AM|pm|PM))?)'; // HourMinuteSec 1

    var re10 = '(\\d+)'; // Integer Number 1

    var p = new RegExp(re1 + re2 + re1 + re4 + re1 + re4 + re1 + re10 + re1, ["i"]);
    var m = p.exec(txt);
    var slot = null;
    if (!m) {
      alert('Please enter a valid availability.')
    } else {
      slot = {
        fromDate: moment(m[1] + m[2], dateTimeFormat).valueOf(),
        toDate: moment(m[1] + m[3], dateTimeFormat).valueOf(),
        duration: m[4]
      };

    }
    return slot;

  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c === 'x'
          ? r
          : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

function setName(name) {
  params.event.name = name;
  actions.updateTemporalEvent(params.event);
}

function setDescription(desc) {
  params.event.description = desc;
  actions.updateTemporalEvent(params.event);
}

  return <div className="row">
    <div className="col-lg-12">

      <ul className="breadcrumb">
        <li>
          <a href="#event">Activities</a>
        </li>
        <li className="active">{pageTitle}</li>
      </ul>
      <form name="sentMessage" id="contactForm" onSubmit={saveEvent}>
        <div className="row control-group">
          <div className="form-group col-xs-12 controls">
            <div className="btn-group" id="toggle_event_editing">
              <button type="button" className={ui.style.eventButtonClassName.off} onClick={turnEventOff}>OFF</button>
              <button type="button" className={ui.style.eventButtonClassName.on} onClick={turnEventOn}>ON</button>
            </div>
          </div>
        </div>

        <div className="row control-group">
          <div className="form-group col-xs-12  controls">
            <label htmlFor="name">Activity Name</label>
            <input type="text" className="form-control" id="name" name="name" required="required" data-validation-required-message="Please enter event name." value={params.event.name}   onChange={e => setName(e.target.value)} />
            <p className="help-block text-danger"></p>
          </div>
        </div>
        <div className="row control-group">
          <div className="form-group col-xs-12 controls">
            <label htmlFor="email">Description</label>
            <textarea type="text" className="form-control" id="description" name="description" value={params.event.description} required="required"  onChange={e => setDescription(e.target.value)} />
            <p className="help-block text-danger"></p>
          </div>
        </div>
        <div className="row control-group">
          <div className="form-group col-xs-12 controls">
            <label htmlFor="phone">Enter New Availability</label>
            <div className="input-group">
              <input type="text" className="form-control" placeholder={getSlotForDisplay(defaultSlot, false)} defaultValue={getSlotForDisplay(defaultSlot, false)} ref={function (el) { newSlotInputEl = el; }} />
              <span className="input-group-btn">
                <button className="btn btn-secondary" type="button" onClick={addSlot}>Add Availability</button>
              </span>
            </div>
          </div>

          <p className="help-block text-danger"></p>

        </div>
        <div className="row control-group">

          <div className="col-xs-12 ">
            <label>Availability</label>
            <table className="table">

              <tbody>
                {getSlots()}
              </tbody>
            </table>

          </div>
        </div>

        <br />
        <div id="success"></div>
        <div className="row">
          <div className="form-group col-xs-12">
            <button type="submit" className="btn btn-primary btn-lg pull-right">Save</button>
            <a href="#event" className="btn btn-lg btn-link pull-right">Cancel</a>

          </div>
        </div>
      </form>

    </div>
  </div>;
}

export default  EditEvent;
