import api from './api';

////////////////////////////////////////////////////////////////////////////////
// Model
//
var model = {};


model.present = function (data) {
  console.debug('2. model received:', data);
  data = data || {};

  model.loading = false;
  model.action = undefined;
  if (data.isRestricted) {
    model.isRestricted = true;
  };

  if (data.action === 'login') {

    console.debug('user is not logged in');

    model.event = undefined;
    model.events = undefined;
    model.bookings = undefined;
    model.user = undefined;
  } else if (data.action === 'authenticated') {

    model.user = {
      name: data.name,
      email: data.email
    };
    model.action = 'authenticated';
    model.destinationUrl = data.destinationUrl;

  } else if (data.action === 'signedout') {

    console.debug('user is signedout');

    model.event = undefined;
    model.events = undefined;
    model.bookings = undefined;
    model.user = undefined;
    model.action = 'signedout';

  } else if (data.action === 'newevent') {

    model.event = {
      on: true,
      slots: []
    };
    model.events = undefined;
    model.bookings = undefined;

  } else if (data.action === 'editevent') {

    model.event = data.event;
    model.events = undefined;

  } else if (data.action === 'saveevent') {

    var callback = function (data) {
      console.debug("event", data);
      model.event = data;
      model.loading = false;
      model.action = 'savedevent';

      model.state.render(model);
    };

    api.saveEvent(data.event, callback);

    model.loading = true;
    model.loadingMsg = "Saving ...";


  } else if (data.action === 'removeevent') {

    var callback = function (data) {
      console.debug("event", data);
      //model.event = data;
      model.loading = false;
      model.action = 'removedevent';

      model.state.render(model);
    };

    api.removeEvent(data.eventId, callback);

    model.loading = true;
    model.loadingMsg = "Saving ...";


  } else if (data.action === 'eventlist') {

    model.events = data.events;
    model.event = undefined;
    model.bookings = undefined;

  } else if (data.action === 'bookinglist') {

    model.bookings = data.bookings;
    model.event = undefined;
    model.events = undefined;

  } else if (data.action === 'updatetemporalevent') {

    model.event = data.event;


  } else if (data.action === 'calendar') {

    if (data.calendar)
      model.calendar = data.calendar;
    model.calendardaydetail = undefined;

  } else if (data.action === 'calendardaydetail') {

    if (data.day)
      model.calendardaydetail = data.day;
    model.calendarselectedspot = undefined;

  } else if (data.action === 'spotselected') {

    model.calendarselectedspot = data.spot;

  } else if (data.action === 'scheduleattendee') {

    var callback = function (data, error) {
      if (error) {
        if (error === 'Not Found') {
          alert('This time spot is already taken.')
        } else {
          alert('Error during scheduling. Please try again later.')
        }
        model.action = 'spottaken';
      } else {
        console.debug("booking", data);
        model.calendarbooking = data;
      }
      model.loading = false;
      model.state.render(model);
    };

    api.addBooking({
      eventId: model.calendar.event.id,
      startDate: model.calendarselectedspot.start,
      endDate: model.calendarselectedspot.end,
      attendee: data.attendee
    }, callback);

    model.loading = true;
    model.loadingMsg = "Saving ...";

  } else if (data.action === 'loading') {

    model.loading = true;
    model.loadingMsg = (data.loadingMsg) ? data.loadingMsg + " ..." : "Loading ...";
  }


  // next step of the reactive loop: compute the state representation
  model.state.render(model);
}

export default model;
