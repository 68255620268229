import api from './api';
import moment from 'moment-timezone';

////////////////////////////////////////////////////////////////////////////////
// Actions
//

var actions = {

  default_landing_page_url: "#event",
  login_page_url: "#login",
  calendar_page_url: "#calendar"
};



actions.navigateTo = function (destinationUrl, present) {
  present = present || actions.present;

  actions.util.changeUrl(destinationUrl);
  return false;
}

// real action methods
//
actions.loadInitial = function (data, present) {
  present = present || actions.present;
  console.debug('0.loadInitial window.location.hash', window.location.hash);
  // listen url changes
  window.addEventListener("hashchange", function (e) {
    actions.util.handleNavChange();
  }, false);

  // is it calendar app?
  if (window.location.hash && window.location.hash.indexOf(actions.calendar_page_url) > -1) {

    actions.util.changeUrl(window.location.hash);


  } else {

    // var userName = 'dsdsd';
    //     var userEmail = 'sdfdf';

    //     present({
    //       action: "authenticated",
    //       name: userName,
    //       email: userEmail,
    //       destinationUrl: (window.location.hash) ? window.location.hash : actions.default_landing_page_url
    //     });
        
    //load google auth api
    window.gapi.load('auth2', function () {
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      actions.auth2 = window.gapi.auth2.init({
        client_id: '694500976728-5cfcofis33nqp14beriq790o11j8ioor.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        // Request scopes in addition to 'profile' and 'email'
        //scope: 'additional_scope'
      });

      var authToken = sessionStorage.getItem('authToken');

      // if auth token exists then user is logged in
      if (authToken) {

        var userName = sessionStorage.getItem('userName');
        var userEmail = sessionStorage.getItem('userEmail');

        present({
          action: "authenticated",
          name: userName,
          email: userEmail,
          destinationUrl: (window.location.hash) ? window.location.hash : actions.default_landing_page_url
        });

      } else {
        actions.util.changeUrl(window.location.hash);
      }

    });


  }

}

actions.signinToGoogle = function (data, present) {
  present = present || actions.present;
  //google login
  actions.auth2.signIn({
    prompt: 'select_account'
  }).then(function () {
    console.log(actions.auth2.currentUser.get());
    var googleUser = actions.auth2.currentUser.get();

    actions.loginViaGoogleToken({
      id_token: googleUser.getAuthResponse().id_token,
      name: googleUser.getBasicProfile().getName(),
      email: googleUser.getBasicProfile().getEmail()
    });

  });
  // present({
  //   action: "loading",
  //   loadingMsg: "signing in to Google"
  // });
}

actions.loginViaGoogleToken = function (googleUser, present) {
  present = present || actions.present;

  var id_token = googleUser.id_token;
  if (!id_token) {
    return false;
  };

  sessionStorage.setItem('userName', googleUser.name);
  sessionStorage.setItem('userEmail', googleUser.email);

  api.loginGoogle(id_token,
    function () {


      var presentAuthenticated = function () {
        present({
          action: "authenticated",
          name: googleUser.name,
          email: googleUser.email,
          destinationUrl: (window.location.hash !== actions.login_page_url) ? window.location.hash : actions.default_landing_page_url
        });

      }
      api.getAccount(function (data) {

        if (!data) {
          var account = {
            name: googleUser.email + ' account',
            url: '',
            owner: {
              name: googleUser.name,
              email: googleUser.email
            }
          };
          api.addAccount(account, function (data) {
            presentAuthenticated();
          });
        } else {
          presentAuthenticated();
        }

      });





    });

  present({
    action: 'loading',
    loadingMsg: "signing in"
  });

}

actions.signout = function (model, present) {
  present = present || actions.present;


  actions.auth2.signOut().then(function () {
    console.log('User signed out.');
    sessionStorage.clear();
    present({
      action: "signedout"
    });
  });


}

actions.loadLogin = function (data, present) {
  present = present || actions.present;

  present({
    action: "login",
    isRestricted: true
  });
  return false;
}

actions.loadEventlist = function (data, present) {
  present = present || actions.present;

  var callback = function (data) {
    console.debug("action loadEventlist", data);
    present({
      action: "eventlist",
      events: data
    });

  };
  //load event list
  //	!!api call

  api.getEvents(callback);

  present({
    action: "loading"
  });

  // var events = [{
  // 	name: "event one",
  // 	id: 1
  // }, {
  // 	name: "event two",
  // 	id: 2
  // }];

  // setTimeout(callback, 1000, events);

}

actions.loadSchedule = function (data, present) {
  present = present || actions.present;

  var callback = function (data) {
    console.debug("action loadSchedule", data);
    present({
      action: "bookinglist",
      bookings: data
    });

  };
  //load event list
  //	!!api call

  api.getBookings(callback);

  present({
    action: "loading"
  });


}

actions.loadEvent = function (data, present) {
  present = present || actions.present;
  var eventId = data;

  if (eventId === 'new') {

    present({
      action: "newevent"
    });

  } else {

    var callback = function (data) {
      console.debug("id", eventId);
      present({
        action: "editevent",
        event: data
      });

    };

    //load specific event
    // !! api call
    api.getEvent(eventId, callback);
    //
    // var event = {
    // 	"id": eventId,
    // 	"name": "testevent",
    // 	"accountId": "abc",
    // 	"slots": [{
    // 			"duration": 15,
    // 			"fromDate": 1481644800000,
    // 			"repeatWeekly": true,
    // 			"toDate": 1481662800000
    // 		}, {
    // 			"duration": 15,
    // 			"fromDate": 1481738400000,
    // 			"repeatWeekly": true,
    // 			"toDate": 1481745600000
    // 		}, {
    // 			"duration": 15,
    // 			"fromDate": 1481212800000,
    // 			"repeatWeekly": false,
    // 			"toDate": 1481216400000
    // 		}

    // 	]
    // };
    // setTimeout(callback, 1000, event);

    present({
      action: "loading"
    });

  }


}
actions.saveEvent = function (data, present) {
  present = present || actions.present;
  var event = data;


  present({
    action: "saveevent",
    event: data
  });


}
actions.removeEvent = function (data, present) {
  present = present || actions.present;
  var eventId = data;


  present({
    action: "removeevent",
    eventId: eventId
  });


}

// actions.addEventSlot = function(data, present) {
// 	present = present || actions.present;
// 	present({
// 		action: "addeventslot",
// 		slot: data
// 	});

// }

// actions.removeEventSlot = function(data, present) {
// 	present = present || actions.present;
// 	present({
// 		action: "removeeventslot",
// 		id: data
// 	});

// }

actions.updateTemporalEvent = function (data, present) {
  present = present || actions.present;
  var event = data;


  present({
    action: "updatetemporalevent",
    event: data
  });

}

//------------ CALENDAR

actions.loadCalendar = function (eventId, startDate, present) {
  present = present || actions.present;

  var callback = function (data, error) {
    if (error) {
      if (error === 'Not Found') {
        alert('Unavailable for scheduling. Please contact your organizer.')
      } else {
        alert('An Error occured. Please try again later.')
      }

    } else {
      console.debug("action loadCalendar", data);
      present({
        action: "calendar",
        calendar: data
      });
    }



  };

  var startDate = (startDate) ? moment(startDate) : moment().startOf('week');
  startDate = startDate.valueOf();

  api.getCalendar(eventId, startDate, callback);

  present({
    action: "loading"
  });


}


actions.gotoCalendarDayDetail = function (day, present) {
  present = present || actions.present;


  present({
    action: "calendardaydetail",
    day: day
  });

}

actions.backToCalendar = function (day, present) {
  present = present || actions.present;


  present({
    action: "calendar"
  });

}

actions.selectSpot = function (data, present) {
  present = present || actions.present;

  present({
    action: "spotselected",
    spot: data.spot
  });
  return false;
}

actions.backToCalendarDayDetail = function (day, present) {
  present = present || actions.present;


  present({
    action: "calendardaydetail"
  });

}

actions.scheduleAttendee = function (attendee, present) {
  present = present || actions.present;


  present({
    action: "scheduleattendee",
    attendee: attendee
  });

}

actions.gotoPrevWeek = function (eventId, currentStartDate, present) {
  present = present || actions.present;
  var startDate = moment(currentStartDate).add(-7, 'day').valueOf();
  actions.loadCalendar(eventId, startDate, present);


}

actions.gotoNextWeek = function (eventId, currentStartDate, present) {
  present = present || actions.present;
  var startDate = moment(currentStartDate).add(7, 'day').valueOf();
  actions.loadCalendar(eventId, startDate, present);


}
actions.gotoToday = function (eventId, present) {

  actions.loadCalendar(eventId);


}

//------------ UTILS

actions.util = {};

// url change listener
actions.util.handleNavChange = function () {

  // get url params
  //anything after hash
  //var match = window.location.hash;//  newURL.match(/#(.*)$/);
  console.debug('1. handleNavChange hash:', window.location.hash);
  var fragment = window.location.hash;
  if (fragment)
    fragment = fragment.substring(1);
  else 
    actions.util.changeUrl('#event');
  //cleanup frag
  fragment = fragment.replace(/\/$/, '').replace(/^\//, '');
  //find query string agrs
  var match = fragment.split('?');
  var urlArgs = {};
  var appAction = match[0];
  if (match.length > 1) {
    match[1].split('&').forEach(function (pair) {
      var nameValue = pair.split('=');
      urlArgs[nameValue[0]] = nameValue[1];
    });
  }
  urlArgs.action = appAction.split('\/');

  //load initial
  console.debug('urlArgs', JSON.stringify(urlArgs));
  if (urlArgs) {
    // route to correct action
    if (urlArgs.action[0] === 'login') {

      actions.loadLogin();

    } else if (urlArgs.action[0] === 'calendar' && urlArgs.action[1]) {

      actions.loadCalendar(urlArgs.action[1]);

    } else {
      // if not authenticated then go to login
      var authToken = sessionStorage.getItem('authToken');
      if (!authToken) {
        actions.util.changeUrl(actions.login_page_url);
        return;
      }

      if (urlArgs.action[0] === 'event') {

        if (!urlArgs.action[1]) {

          actions.loadEventlist();

        } else {

          actions.loadEvent(urlArgs.action[1]);

        }
      } else if (urlArgs.action[0] === 'schedule') {

        actions.loadSchedule();

      } 
    }

  }

}

actions.util.changeUrl = function (newUrl) {
  if (newUrl !== window.location.hash) {
    window.location.href = newUrl;
  } else
    actions.util.handleNavChange();
}

export default actions;
