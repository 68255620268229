////////////////////////////////////////////////////////////////////////////////
// State
//
var state = {

};

// Derive the state representation as a function of the systen
// control states
state.representation = function (model) {
  var representation = 'oops... something went wrong, the system is in an invalid state';
  console.debug('3. state received:', model);
  var representationStr = '';

  if (model.calendarselectedspot || model.calendarbooking) {
    representation = state.view.calendarConfirmation(model);
    representationStr = ' state.view.calendarConfirmation';
  } else if (model.calendardaydetail) {
    representation = state.view.calendarDayDetail(model);
    representationStr = ' state.view.calendarDayDetail';
  } else if (model.calendar) {
    representation = state.view.calendar(model);
    representationStr = ' state.view.calendar';
  } else if (model.isRestricted && !model.user) {

    representation = state.view.login(model);
    representationStr = 'state.view.login';
  } else if (model.events) {
    representation = state.view.eventList(model);

  } else if (model.event) {
    representation = state.view.editEvent(model);

  } else if (model.bookings) {
    representation = state.view.bookingList(model);

  } else {

    //do nothing
    //console.error('invalid state');
    return;
  }
  console.debug('3. state representation', representationStr);
  representation = state.view.app(representation, model);
  state.view.display(representation);
}



// Next action predicate, derives whether
// the system is in a (control) state where
// an action needs to be invoked

state.nextAction = function (model) {

  //find better way
  if (model.action === 'authenticated') {
    console.debug('state.nextAction ', 'actions.navigateTo', model.destinationUrl);
    state.actions.navigateTo(model.destinationUrl);
  } else if (model.action === 'savedevent' || model.action === 'removedevent') {
    console.debug('state.nextAction ', 'actions.navigateTo', state.actions.default_landing_page_url);
    state.actions.navigateTo(state.actions.default_landing_page_url);
  } else if (model.action === 'signedout') {
    console.debug('state.nextAction ', 'actions.navigateTo', state.actions.login_page_url);
    state.actions.navigateTo(state.actions.login_page_url);
  }
  // else if (model.action === 'spottaken') {
  //   console.debug('state.nextAction ', 'actions.navigateTo', state.actions.calendar_page_url);
  //   state.actions.navigateTo(state.actions.calendar_page_url);
  // }


}

state.render = function (model) {
  state.representation(model)
  state.nextAction(model);
}

export default state;
