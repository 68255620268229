import {createRoot} from 'react-dom/client';
import Login  from './components/Login.jsx';
import EventList  from './components/EventList.jsx';
import EditEvent  from './components/EditEvent.jsx';
import App  from './components/App.jsx';
import BookingList  from './components/BookingList.jsx';
import Calendar  from './components/Calendar.jsx';
import CalendarDayDetail  from './components/CalendarDayDetail.jsx';
import CalendarConfirmation  from './components/CalendarConfirmation.jsx';

////////////////////////////////////////////////////////////////////////////////
// View
//
var view = {

};

view.app = function (component, model) {

	return view.app(component, model);

}

// Login State
view.login = function (model) {

	return view.login(model);

}


view.eventList = function (model) {

	return view.eventList(model);

}

view.editEvent = function (model) {

	return view.editEvent(model);

}

view.bookingList = function (model) {

	return view.bookingList(model);

}

view.calendar = function (model) {

	return view.calendar(model);

}

view.calendarDayDetail = function (model) {

	return view.calendarDayDetail(model);

}

//display the state representation
view.display = function (representation) {

	if (!this.root) {
		const rootElement = document.getElementById('root');
		this.root = createRoot(rootElement);
	}



	this.root.render(  
				representation
	 ,
	);

}

////////////////////////////////////////////////////////////////////////////////
// React Components
//



view.login = Login;
view.eventList = EventList;
view.editEvent = EditEvent;
view.app = App;
view.bookingList = BookingList;
view.calendar = Calendar;
view.calendarDayDetail = CalendarDayDetail
view.calendarConfirmation = CalendarConfirmation;

export default view;