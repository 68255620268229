import actions from '../actions';
import moment from 'moment-timezone';

var CalendarConfirmation = function (params) {
  var ui = {
    style: {
      backToCalendarDayDetailClassName: ('nav-back-button' + ((params.calendarbooking) ? ' hidden' : ''))
    }
  };

  console.log("this.getParams().start:" + params.calendarselectedspot.start);

  var startDate = moment(params.calendarselectedspot.start);
  var spotLabel = startDate.format("ddd, MMM D h:mm a");
  var endDate = moment(params.calendarselectedspot.end);
  spotLabel += endDate.format(" - h:mm a");
  var googleCalEvent = "http://www.google.com/calendar/render?action=TEMPLATE";
  googleCalEvent += "&";
  googleCalEvent += "dates=" + startDate.format("YYYYMMDDThhmmss") + "Z/" + endDate.format("YYYYMMDDThhmmss") + "Z";
  googleCalEvent += "&";
  googleCalEvent += "details=Scheduled to attend " + params.calendar.event.name + " on " + spotLabel;
  googleCalEvent += "&";
  googleCalEvent += "location=";
  googleCalEvent += "&";
  googleCalEvent += "text=" + params.calendar.event.name;

  var backToCalendarDayDetail = function (e) {
    e.preventDefault();
    e.stopPropagation();

    actions.backToCalendarDayDetail(params);

  }

  var scheduleAttendee = function (e) {
    e.preventDefault();
    e.stopPropagation();

    actions.scheduleAttendee({ email: emailInputEl.value, name: nameInputEl.value });

  }

  var scheduleInfo;
var emailInputEl;
var nameInputEl;

  if (!params.calendarbooking) {
    scheduleInfo = (<form onSubmit={scheduleAttendee}>
      <div className="form-group">
        <label for="exampleInputEmail1">Email</label>
        <input type="email" className="form-control" placeholder="Enter email" ref={function (el) {
          emailInputEl = el;
        }} />
      </div>
      <div className="form-group">
        <label for="exampleInputPassword1">Full Name</label>
        <input type="text" className="form-control" placeholder="Enter full name" ref={function (el) {
          nameInputEl = el;
        }} />
      </div>
      <button type="submit" className="btn btn-primary">Confirm</button>
    </form>);
  } else {
    scheduleInfo = (<div className="invitee-form own-row ptl">
      <div className="invitee-form-column col1of2 mtm prm">
        <div className="confirmation">
          <h3>Confirmed for {params.calendarbooking.attendee.name}</h3>
        </div>
      </div>
      <div className="">
        <h5>Add to your calendar</h5>
        <p>

          <a href={googleCalEvent} target="_blank"> <i className="fa fa-calendar-plus-o" aria-hidden="true"></i>&nbsp;Google Calendar</a>


        </p>
      </div>
    </div>);
  }

  return (<div className="wrapper">
    <span className={ui.style.backToCalendarDayDetailClassName} onClick={backToCalendarDayDetail} >
      <i className="fa fa-3x fa-arrow-circle-left" aria-hidden="true"> </i>
    </span>
    <div className="row" style={{
      paddingTop: '20px'
    }}>
      <div className="col-md-6">
        <p>
          <i className="fa fa-tasks" aria-hidden="true"></i>&nbsp;
          {params.calendar.event.name}</p>
        <p>
          <i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;
          {spotLabel}</p>
        <p>
          <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
          {params.calendar.event.description}</p>
      </div>
      <div className="col-md-6">
        {scheduleInfo}

      </div>
    </div>

  </div>);
}

export default  CalendarConfirmation;
