import moment from 'moment';

var BookingList = function (params) {
  var dateTimeFormat = 'M/D/YYYY h:mm a';
  var timeFormat = 'h:mm a';

  if (params.bookings && params.bookings.length > 0) {

    var bookingListNodes = params.bookings.map(function (booking, index) {

      var date = moment(booking.startDate).format(dateTimeFormat);
      var duration = Math.round((booking.endDate - booking.startDate) / (60 * 1000));

      return (<tr key={index}>
        <td>
          {date} - {duration}
          min
        </td>
        <td>
          {booking.eventName}
        </td>
        <td>
          {booking.attendee.name}
        </td>
        <td>
          {booking.attendee.email}
        </td>
      </tr>);

    });

    var bookings = (<div>
      <p>
        Your upcoming activities
      </p>
      <table className="table">
        <thead>
          <tr>
            <th className="col-lg-2">Time</th>
            <th className="col-lg-2">Activity</th>
            <th className="col-lg-2">Name</th>
            <th className="col-lg-2">Email</th>
          </tr>
        </thead>
        <tbody>
          {bookingListNodes}
        </tbody>
      </table>
    </div>);

  } else {

    var bookingListEmptyMessage = (<p>
      You have no scheduled activities at this moment
    </p>);

  }

  return (<div className="row">
    <div className="col-lg-12">
      {bookingListEmptyMessage}
      {bookings}

    </div>
  </div>);
}

export default  BookingList;
