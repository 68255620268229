import actions from '../actions';
import logo from '../../img/easyschedulrlogo.png';

var App = function (component, params) {
  console.debug('app ui params', params);

  if (params.user) {
    var ui = {
      style: {
        navActiveTab: {
          events: (
            (params.events || params.event)
              ? "active"
              : ""),
          schedule: (
            (params.bookings)
              ? "active"
              : "")
        }
      }
    };

    var signout = function (e) {
      e.preventDefault();
      e.stopPropagation();

      actions.signout();
    };

    var userDropdown = (<ul className="nav nav-pills pull-right">
      <li role="presentation" className={ui.style.navActiveTab.events}>
        <a href="#event">Activities</a>
      </li>
      <li role="presentation" className={ui.style.navActiveTab.schedule}>
        <a href="#schedule">My Schedule</a>
      </li>
      <li className="dropdown">
        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i className="fa fa-user-circle fa-lg" aria-hidden="true"></i> {params.user.name} <span className="caret"></span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a id="signoutLink" href="#" onClick={signout}>Sign out</a>
          </li>

        </ul>
      </li>
    </ul>);

  };

  if (params.loading) {
    var loadingIndicator = (<div id="loading-indicator" className="alert-warning loading-indicator col-lg-offset-6">{params.loadingMsg}</div>);
  };

  return (<div>
    <div className="container">
      <div className="header clearfix">
        <nav>
          {userDropdown}
        </nav>
        {
          (!params.calendar) ?
            (<a href="#"><img className="img-responsive img-watch easyschedulrlogo" src={logo} alt="" /></a>) :
            (<img className="img-responsive img-watch easyschedulrlogo" src={logo} alt="" />)
        }

      </div>

      {component}

    </div>

    <footer className="footer">
      <div className="container">
        <p className="text-muted">&copy; 2022 EasySchedulr.com&nbsp;
              <a href="https://www.facebook.com/easyschedulr" style={{ color: "#4d90fe" }}><i id="social-fb" className="fa fa-facebook-square fa-2x social"></i></a>
          <a href="https://twitter.com/easyschedulr" style={{ color: "#4d90fe" }}><i id="social-tw" className="fa fa-twitter-square fa-2x social"></i></a>
          <a href="mailto:easyschedulrinfo@gmail.com" style={{ color: "#4d90fe" }}><i id="social-em" className="fa fa-envelope-square fa-2x social"></i></a></p>
      </div>
    </footer>
    {loadingIndicator}
  </div>);

}

export default  App;
