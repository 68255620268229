import actions from '../actions';
import moment from 'moment-timezone';

var CalendarDay = function (params) {
  // console.log('params:',params);
  var date = moment(params.day.date);
  var spotDisabledClassName = (params.model.loading || params.day.spots.length === 0)
    ? 'disabled'
    : 'base';


  var gotoDayDetail = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (params.model.loading)
      return false;

    actions.gotoCalendarDayDetail(params.day);
  }

  return (<td className="st-c">
    <div className="st-dtitle st-dtitle-fr st-dtitle-fc st-dtitle-nonmonth">
      <span className="ca-cdp24413">{date.format("MMM") + ' ' + date.format("D")}</span>
    </div>
    <div className="te-s" onClick={function (e) {
      gotoDayDetail(e);
    }}>
      <div>
        <span >{params.day.spots.length}</span>
      </div>
      <div>
        <span>Start Times</span>
      </div>
    </div>
  </td>);

}
export default  CalendarDay;
