import $ from 'jquery';

////////////////////////////////////////////////////////////////////////////////
// Api
//
var api = {
  serviceProviderHost: 'https://xtes1putm7.execute-api.us-east-1.amazonaws.com/dev',
  authProviderHost: 'https://5qcf38ziub.execute-api.us-east-1.amazonaws.com/dev'

};


api.loginGoogle = function(id_token, callback) {

  $.ajax({
    url: api.authProviderHost + '/authentication/googleverify',
    data: JSON.stringify({
      id_token: id_token
    }),
    method: 'post'
  }).done(
    function(data, status, request) {
      console.debug('token', data);
      sessionStorage.setItem('authToken', JSON.stringify(data));
      callback(data);
    }.bind(this)
  );

}

api.getAccount = function(callback) {

  api.beforeServiceCall().then(function(authToken) {
    $.ajax({
      url: api.serviceProviderHost + '/account',
      headers: {
        'Authorization':  authToken.authorizationToken
      }
    }).done(
      function(data, status, request) {
        console.debug('accounts', data);
        var account = null;
        if (data && data.length > 0)
          account = data[0];
        callback(account);
      }.bind(this)
    );
  });

}

api.addAccount = function(account, callback) {

  api.beforeServiceCall().then(function(authToken) {
    $.ajax({
      url: api.serviceProviderHost + '/account',
      data: JSON.stringify(account),
      method: 'post',
      headers: {
        'Authorization':  authToken.authorizationToken
      }
    }).done(
      function(data, status, request) {
        console.debug('new account', data);
        callback(data);
      }.bind(this)
    );
  });
}


api.getEvents = function(callback) {



  api.beforeServiceCall().then(function(authToken) {

    $.ajax({
      url: api.serviceProviderHost + '/event',
      headers: {
        'Authorization': authToken.authorizationToken
      }
    }).done(
      function(data, status, request) {
        console.debug('events', data);
        callback(data);
      }.bind(this)
    );

  });



}

api.getEvent = function(id, callback) {

  api.beforeServiceCall().then(function(authToken) {

    $.ajax({
      url: api.serviceProviderHost + '/event/' + id,
      headers: {
        'Authorization': authToken.authorizationToken
      }
    }).done(
      function(data, status, request) {
        console.debug('events', data);
        callback(data);
      }.bind(this)
    );

  });



}

api.saveEvent = function(event, callback) {

  api.beforeServiceCall().then(function(authToken) {

    var method = 'post';
    if (event.id) {
      method = 'put'
    }
    $.ajax({
      url: api.serviceProviderHost + '/event' + ((method === 'put') ? '/' + event.id : ''),
      data: JSON.stringify(event),
      method: method,
      headers: {
        'Authorization': authToken.authorizationToken
      }
    }).done(
      function(data, status, request) {
        console.debug('new account', data);
        callback(data);
      }.bind(this)
    );
  });


}

api.removeEvent = function(id, callback) {

  api.beforeServiceCall().then(function(authToken) {

    $.ajax({
      url: api.serviceProviderHost + '/event/' + id,
      method: 'delete',
      headers: {
        'Authorization': authToken.authorizationToken
      }
    }).done(
      function(data, status, request) {
        console.debug('event', data);
        callback(data);
      }.bind(this)
    );

  });



}
api.getBookings = function(callback) {

  api.beforeServiceCall().then(function(authToken) {

    return $.ajax({
      url: api.serviceProviderHost + '/booking',
      headers: {
        'Authorization': authToken.authorizationToken
      }
    })

  }).done(
    function(bookings, status, request) {
      console.debug('bookings', bookings);
      // bookings.map(function(booking) {
      //     booking.eventName = eventMap[booking.eventId];
      // });
      callback(bookings);
    }.bind(this)
  );

}

///--------------

api.getCalendar = function(eventId, startDate, callback) {

  $.ajax({
    url: api.serviceProviderHost + '/event/' + eventId + '/spot' + '?' + 'startDate=' + startDate
  }).done(
    function(data, status, request) {
      console.debug('events', data);
      callback(data);
    }.bind(this)
  ).fail(function(xhr) {
    console.debug(xhr)
    callback(null, ((xhr.status === 404) ? 'Not Found' : 'Error'));

  });

}

api.addBooking = function(booking, callback) {
  console.debug('addbooking booking', booking);
  var requestData = {
    "eventId": booking.eventId,
    "startDate": booking.startDate,
    "endDate": booking.endDate,
    "attendee": {
      "email": booking.attendee.email,
      "name": booking.attendee.name
    }
  };
  console.debug('addbooking requestData', requestData);
  $.ajax({
    url: api.serviceProviderHost + '/booking/add',
    data: JSON.stringify(requestData),
    method: 'post',
    contentType: "application/json; charset=utf-8",
    dataType: "json"
  }).done(function(data, status, request) {
    callback(data);
  }).fail(function(xhr) {
    callback(null, ((xhr.status === 404) ? 'Not Found' : 'Error'));

  });


}

api.parseJwt = function(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

api.beforeServiceCall = function() {

  var token = sessionStorage.getItem('authToken');

  if (!token) {
    var deferred = $.Deferred();
    return deferred.reject('Not Authenticated');

  }
  token = JSON.parse(token);
  var parsedJwt = api.parseJwt(token.authorizationToken);
  console.debug('beforeServiceCall parsedJwt', parsedJwt);
  var now = new Date();
  var isExpired = (parsedJwt.exp < (now.getTime() / 1000)) ? true : false;
  // if token not expired return token
  if (!isExpired) {
    console.debug('beforeServiceCall token still valid');
    var deferred = $.Deferred();
    deferred.resolve(token);
    return deferred.promise();
  }
  // otherwise refresh token
  console.debug('beforeServiceCall will call refresh token');
  return $.ajax({
    url: api.authProviderHost + '/authentication/refresh/' + token.refreshToken
  }).done(function(data) {
    console.debug('got new token', data);
    sessionStorage.setItem('authToken', JSON.stringify(data));
  });

};



export default api;
